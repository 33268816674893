import React, { useEffect, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { success } from "../../../../components/Toast";
import useApi from "../../../../hooks/useApi";

import {
  ModalGatewayWindowContainer,
  Button,
  WindowDetailsContainer,
  WindowDetailsItem,
} from "./styles";
import { Loading } from "../../../../components";

type ModalGatewayWindowProps = {
  gatewayId: string;
};

const gatewayParameterOptions = [
  {
    description: "Janela default",
    radioWindowNumBoardSlots: 240,
    numBoards: 120,
    dataAcquisitionInterval: 600,
    radioWindowInterval: 3600,
    dynamicTxInterval: 86400,
    configWindow: 600,
    globalTxWindow: 5,
    dynamicTxWindow: 180,
    batteryLifeTimeDays: 256,
  },
  {
    radioWindowNumBoardSlots: 120,
    numBoards: 120,
    dataAcquisitionInterval: 1200,
    radioWindowInterval: 7200,
    dynamicTxInterval: 86400,
    configWindow: 600,
    globalTxWindow: 40,
    dynamicTxWindow: 180,
    batteryLifeTimeDays: 342,
  },
  {
    description: "Homologação",
    radioWindowNumBoardSlots: 4,
    numBoards: 4,
    dataAcquisitionInterval: 60,
    radioWindowInterval: 500,
    dynamicTxInterval: 1000,
    configWindow: 120,
    globalTxWindow: 5,
    dynamicTxWindow: 180,
    batteryLifeTimeDays: 13,
  },
  {
    radioWindowNumBoardSlots: 8,
    numBoards: 8,
    dataAcquisitionInterval: 60,
    radioWindowInterval: 560,
    dynamicTxInterval: 2240,
    configWindow: 120,
    globalTxWindow: 10,
    dynamicTxWindow: 180,
    batteryLifeTimeDays: 24,
  },
  {
    description: "Sala redutor SEW",
    radioWindowNumBoardSlots: 16,
    numBoards: 16,
    dataAcquisitionInterval: 120,
    radioWindowInterval: 600,
    dynamicTxInterval: 9600,
    configWindow: 120,
    globalTxWindow: 15,
    dynamicTxWindow: 240,
    batteryLifeTimeDays: 75,
    sendDynamic: false,
  },
  {
    radioWindowNumBoardSlots: 20,
    numBoards: 20,
    dataAcquisitionInterval: 60,
    radioWindowInterval: 400,
    dynamicTxInterval: 8000,
    configWindow: 120,
    globalTxWindow: 5,
    dynamicTxWindow: 180,
    batteryLifeTimeDays: 38,
  },
  {
    description: "SEW - Suzano",
    radioWindowNumBoardSlots: 30,
    numBoards: 30,
    dataAcquisitionInterval: 180,
    radioWindowInterval: 600,
    dynamicTxInterval: 18000,
    configWindow: 180,
    globalTxWindow: 10,
    dynamicTxWindow: 120,
    batteryLifeTimeDays: 76,
  },
  {
    radioWindowNumBoardSlots: 30,
    numBoards: 30,
    dataAcquisitionInterval: 60,
    radioWindowInterval: 450,
    dynamicTxInterval: 13500,
    configWindow: 120,
    globalTxWindow: 5,
    dynamicTxWindow: 180,
    batteryLifeTimeDays: 45,
  },
  {
    radioWindowNumBoardSlots: 2,
    numBoards: 2,
    dataAcquisitionInterval: 60,
    radioWindowInterval: 150,
    configWindow: 120,
    globalTxWindow: 10,
    dynamicTxWindow: 10,
  },
  {
    description: "Tirolez",
    radioWindowNumBoardSlots: 72,
    numBoards: 72,
    dataAcquisitionInterval: 300,
    radioWindowInterval: 1200,
    dynamicTxInterval: 86400,
    configWindow: 300,
    globalTxWindow: 5,
    dynamicTxWindow: 180,
    batteryLifeTimeDays: 202,
  },
];

function formatSeconds(totalSeconds: number) {
  let hours;
  let minutes;
  let seconds;

  if (totalSeconds > 3600) {
    hours = Math.floor(totalSeconds / 3600);
    minutes = Math.floor(totalSeconds / 60) - hours * 60;
    return `${hours}h${minutes ? ` ${minutes}m` : ""}`;
  }

  if (totalSeconds < 60) {
    return `${totalSeconds}s`;
  }

  minutes = Math.floor(totalSeconds / 60);
  seconds = totalSeconds % 60;

  return `${minutes}m ${seconds ? `${seconds}s` : ""}`;
}

export const ModalGatewayWindow = ({ gatewayId }: ModalGatewayWindowProps) => {
  const { request, processing } = useApi({
    path: `/configurator/gateways/${gatewayId}`,
  });

  const [parameters, setParameters] = useState<any>({});
  const [currentParameters, setCurrentParameters] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      loadOperationFrequency();
    }
    // eslint-disable-next-line
  }, [show]);

  function loadOperationFrequency() {
    request({
      method: "get",
    }).then((response) => {
      let responseParameters;
      if (response.parameters) {
        responseParameters = response.parameters;
      } else {
        responseParameters = {
          radioWindowNumBoardSlots: 240,
          numBoards: 120,
          dataAcquisitionInterval: 600,
          radioWindowInterval: 3600,
          dynamicTxInterval: 86400,
          configWindow: 600,
        };
      }

      setParameters(responseParameters);
      setCurrentParameters(responseParameters);
    });
  }

  function handleSave() {
    if (!processing && parameters) {
      request({
        method: "put",
        body: { parameters },
        pathParameters: "/parameters",
      }).then(() => {
        success("Intervalo Alterado com Sucesso");
        setShow(false);
      });
    }
  }

  function isSelectedWindow(window: any) {
    if (!parameters) {
      return false;
    }

    if (window.radioWindowInterval !== parameters.radioWindowInterval) {
      return false;
    }

    if (window.radioWindowInterval !== parameters.radioWindowInterval) {
      return false;
    }

    if (
      window.radioWindowNumBoardSlots !== parameters.radioWindowNumBoardSlots
    ) {
      return false;
    }

    if (window.configWindow !== parameters.configWindow) {
      return false;
    }

    if (
      window.dynamicTxWindow &&
      parameters.dynamicTxWindow &&
      window.dynamicTxWindow !== parameters.dynamicTxWindow
    ) {
      return false;
    }

    if (
      window.globalTxWindow &&
      parameters.globalTxWindow &&
      window.globalTxWindow !== parameters.globalTxWindow
    ) {
      return false;
    }

    return true;
  }

  const isSameParameters = isSelectedWindow(currentParameters);

  const hasMatchWithSomeDefault = gatewayParameterOptions.findIndex(
    (parameters: any) => isSelectedWindow(parameters)
  );

  const gatewayParameters =
    hasMatchWithSomeDefault !== -1
      ? gatewayParameterOptions
      : [...gatewayParameterOptions, parameters];

  return (
    <>
      <Button onClick={() => setShow(true)}>Parâmetros de transmissão</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        handleConfirm={handleSave}
        confirmLabel="Salvar"
        size="lg"
        hideSaveButton={isSameParameters}
      >
        <ModalGatewayWindowContainer>
          <h1>Opções de parâmetros</h1>
          {processing ? (
            <>
              <Loading />
            </>
          ) : (
            <WindowDetailsContainer>
              <>
                {gatewayParameters.map((parameters: any) => (
                  <WindowDetailsItem
                    selected={isSelectedWindow(parameters)}
                    onClick={() => {
                      setParameters(parameters);
                    }}
                  >
                    <p>Descrição: {parameters.description}</p>
                    <p>Slots: {parameters.radioWindowNumBoardSlots}</p>
                    <p>
                      Nº Sensores:{" "}
                      {parameters.numBoards ||
                        parameters.radioWindowNumBoardSlots}
                    </p>
                    <p>
                      Coleta:{" "}
                      {formatSeconds(parameters.dataAcquisitionInterval)}
                    </p>
                    <p>
                      Transmissão G:{" "}
                      {formatSeconds(parameters.radioWindowInterval)}
                    </p>
                    <p>
                      Transmissão D:{" "}
                      {parameters.sendDynamic === false
                        ? "N/D"
                        : formatSeconds(parameters.dynamicTxInterval)}
                    </p>
                    <p>
                      Configuração: {formatSeconds(parameters.configWindow)}
                    </p>
                    <p>
                      Tempo de tx G:{" "}
                      {formatSeconds(parameters.globalTxWindow || 5)}
                    </p>
                    <p>
                      Tempo de tx D:{" "}
                      {formatSeconds(parameters.dynamicTxWindow || 180)}
                    </p>
                    <p>
                      Estimativa min. bateria*:
                      {parameters.batteryLifeTimeDays
                        ? ` ${parameters.batteryLifeTimeDays} dias`
                        : " N/D"}
                    </p>
                  </WindowDetailsItem>
                ))}
              </>
              <br />
              <p style={{ fontSize: "12px" }}>
                * Estimativa calculada para sensores do tipo Spectra na versão
                1.7 configurado com janela spectral de 6.400Hz e bateria de 3600
                mAh
              </p>
            </WindowDetailsContainer>
          )}
        </ModalGatewayWindowContainer>
      </Modal>
    </>
  );
};
